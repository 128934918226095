import React from 'react';
import './Content.css';
import { Card } from './Card';

function Content() {
  return (
      <>
        <div className="container">
          <p>Youropy est une plateforme innovante, spécialement conçue pour faciliter les échanges de questions/réponses entre utilisateurs. Dans un monde où l'obtention de réponses rapides et fiables peut s'avérer être un défi, Youropy se distingue comme une solution efficace et adaptée.
          Les utilisateurs ont la liberté de poser leurs questions à un public spécifique, sélectionné selon des critères précis pour garantir la pertinence et la fiabilité des réponses. Cela permet une personnalisation et une adaptabilité accrues, assurant que chaque question atteint les personnes les plus qualifiées pour y répondre.
          </p>

          <p>Jusqu'à présent, lorsqu'on se trouve face à une interrogation spécifique, les options pour solliciter l’avis d’autrui sont limitées et souvent inefficaces. On pourrait se tourner vers des réseaux sociaux généralistes comme Twitter, mais la qualité des réponses dépend largement du nombre d’abonnés et de leur engagement. Instagram offre une autre alternative, mais elle est loin d'être idéale pour ceux qui cherchent des perspectives objectives et ne souhaitent pas impliquer leur cercle d’amis dans des discussions potentiellement sensibles ou privées.
          </p>

          <p>La spécificité de Youropy réside dans sa capacité à mettre en relation des utilisateurs ayant des questions avec d'autres possédant l'expertise, l'expérience ou la perspective nécessaire pour fournir des réponses pertinentes sans jugement et en toute liberté. 
          </p>
          
          <h4> Bienvenue dans l'univers de Youropy, où chaque question trouve sa réponse.
          </h4>
        </div>

        <div className="container">
          <p>Inscrivez-vous dès aujourd'hui pour essayer Youropy en avant première en tant que Bêta testeur!
          </p>
          <p>La version Bêta sera disponible fin 2024.  
          </p>
          <p>De nombreuses fonctionnalités sont en préparation, restez connectés pour les découvrir!</p>
        </div>
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        

      </>
  );
}

export default Content;
