import React, { useState } from 'react';
import './Header.css';
import SignUpForm from '../Authentification/SignUpForm';

function Header() {
  return (
    <header>
      <nav className="navbar">
        <h2>Inscription pour la version Bêta</h2>
        <div className="logo">
          <img src="/images/TexteY-removebg.png" alt="Logo" />
        </div>
        <div className="buttons">
          <button id="login" className="btn-login">Connexion</button>
          <button id="signup" className="btn-signup" >S'inscrire</button>
        </div>
      </nav>
    </header>
  );
}

export default Header;






